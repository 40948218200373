/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"

function SEO() {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title="Masaki Nabeshima"
    >
      <meta
        name="description"
        content="Music, software engineering, and birdwatching."
      />
      <meta property="og:title" content="Masaki Nabeshima" />
      <meta
        name="og:description"
        content="Music, software engineering, and birdwatching."
      />
      <meta property="og:type" content="website" />
    </Helmet>
  )
}

export default SEO
